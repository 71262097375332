import React, { useEffect, useMemo, useState } from "react";
import { InsuranceManage } from "./InsuranceManage";
import { shallowEqual, useSelector } from "react-redux";

export const Insurance = (props: any) => {
  const [isShowInsuranceManage, setIsShowInsuranceManage] = useState(false);

  const patientData = useSelector(
    (state: any) => state.patientData.patientDataResponse,
    shallowEqual
  );
  const currentDependant = useMemo(() => {
    const ID = patientData.id;
    return patientData?.dependant?.find(
      (item: any) => Number(item.loginId) === Number(ID)
    );
  }, [patientData]);

  useEffect(() => {
    setIsShowInsuranceManage(props.isShowInsurance);
  }, [props.isShowInsurance]);
  return (
    <>
      <div className="card_item insurance_c card justify-content-between p-2">
        <div className="card_box">
          <div className="infocard_header mb-2">
            <div className="card_ititle">My Payments</div>
            <div style={{ fontSize: "12px" }}>
              {currentDependant?.insuranceName
                ? ""
                : "We don't have insurance details on record, you will be paying out of pocket at the clinic."}
            </div>
          </div>
          <div className="infocard-body p-1">
            <div className="av-txt">
              {currentDependant?.insuranceName ? `Insurance Name` : ""}
            </div>
            <div className="av-txt">
              {currentDependant?.insuranceName
                ? `${currentDependant?.insuranceName}`
                : ""}
            </div>
          </div>
        </div>
        <div className="card_ilink fix_bottom">
          {/* <label>Coming soon...</label> */}
          <button
            type="button"
            className="btn btn-link p-0 fw-normal fs-7"
            onClick={() => {
              setIsShowInsuranceManage(true);
            }}
          >
            {currentDependant?.insuranceName
              ? `View/Update insurance`
              : "Add insurance details"}
          </button>
          {!currentDependant?.insuranceName ? (
            <label style={{ fontSize: "12px" }}>
              *Add or Update your insurance details to earn bonus points.
            </label>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* <div className="card_item insurance_c card p-3">
                <div className="infocard_header d-flex justify-content-between mb-3">
                    <div className="card_ititle">Insurance calculator</div>
                    <div className="card_ilink">
                        <button type="button" className="btn btn-link p-0 fw-normal fs-7" onClick={() => { setIsShowInsuranceManage(true) }}>Manage</button>
                    </div>
                </div>
                <div className="infocard_body">
                    <div className="d-flex">
                        <div className="rewardsp me-3 pe-3 border-end">
                            <div className="fs-7">Spent amt.</div>
                            <div className="fs-4">$1500.00</div>
                        </div>

                        <div className="loyalp">
                            <div className="fs-7">Balance amt.</div>
                            <div className="fs-4">$4000.00</div>
                        </div>
                    </div>
                </div>
            </div> */}

      <InsuranceManage
        isShow={isShowInsuranceManage}
        handleClose={() => {
          setIsShowInsuranceManage(false);
          props.setIsShowInsurance(false);
        }}
      />
    </>
  );
};
